import { Component } from '@angular/core'
import { RouterOutlet } from '@angular/router'

import { TranslateModule, TranslateService } from '@ngx-translate/core'

import { default_language, supported_languages } from './constans/language'

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, TranslateModule],
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(private readonly translate: TranslateService) {
    this.translate.addLangs(Array.from(supported_languages))
    this.translate.setDefaultLang(default_language)

    const language = this.translate.getBrowserLang() ?? default_language
    this.translate.use(language)
  }
}
