export default {
  from: 'Por',
  send: 'Enviar',
  fields: {
    name: 'Nome',
    email: 'E-mail',
    message: 'Mensagem',
  },
  validations: {
    name: {
      required: 'O nome é obrigatório',
    },
    email: {
      required: 'O e-mail é obrigatório',
      invalid: 'O e-mail é inválido',
    },
    message: {
      required: 'A mensagem é obrigatória',
      minlength: 'A mensagem deve conter pelo menos 30 caracteres',
      maxlength: 'A mensagem deve conter no máximo 500 caracteres',
    },
  },
  unexpected_error: 'Tivemos um erro inesperado',
}
